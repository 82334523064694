import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"

// Components
import Seo from "components/seo"

// Page Sections
import FormLanding from "./../screens/webinar-replay/2022-12-05-harnett/form-landing"
import BodyLanding from "./../screens/webinar-replay/2022-12-05-harnett/body-landing"

const HarnettReplay = () => {

	const data = useStaticQuery(graphql`
   query {
      wpWebinar ( webinars: {featured: {eq: true}, existingSlug: {eq: "/webinar-replay/2022-12-05-harnett/"}} ) {
          id
          title
          webinars {
            featured
            featuredDate
            featuredTime
            featuredCost
            featuredUrl
            presenter
            presenterSubtitle
            }
          }
        }
  `)
  return (
    <React.Fragment>
      { data.wpWebinar !==null ? 
      <FormLanding 
        title={data.wpWebinar.title}
        featured
        featuredDate={data.wpWebinar.webinars.featuredDate}
        presenter={data.wpWebinar.webinars.presenter}
        presenterSubtitle={data.wpWebinar.webinars.presenterSubtitle}
        featuredTime={data.wpWebinar.webinars.featuredTime}
        featuredCost={data.wpWebinar.webinars.featuredCost}
        featuredUrl={data.wpWebinar.webinars.featuredUrl}
        extraMessage="1-PACE Credit® | 1-AACN CERP"
       />
       :
       <FormLanding />
      }
      {data.wpWebinar!==null && data.wpWebinar.webinars.featured === true ?
      <BodyLanding pageName="display" 
        localStorageID="2022-12-05-harnett-webinar-replay" 
        featured={data.wpWebinar.webinars.featured} 
      />
     : 
       <BodyLanding pageName="display" localStorageID="2022-12-05-harnett-webinar-replay" />
     }
      </React.Fragment>
  )
}
export default HarnettReplay

export const Head = () => (
  <Seo
	title="Webinar: STIs Are Running Rampant—Are You Up to Date on the Latest Guidelines?"
	description="STIs Are Running Rampant—Are You Up to Date on the Latest Guidelines?"
	image="/meta/webinar-harnett-2022-12-05.jpg"
	recaptcha
  />
)
